import { render, staticRenderFns } from "./TheOrderList.vue?vue&type=template&id=0c6cdcff&scoped=true&"
import script from "./TheOrderList.vue?vue&type=script&lang=js&"
export * from "./TheOrderList.vue?vue&type=script&lang=js&"
import style0 from "./TheOrderList.vue?vue&type=style&index=0&id=0c6cdcff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c6cdcff",
  null
  
)

export default component.exports